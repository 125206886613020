<template>
	<div>
		<div class="main-filter" v-if="checkTab == 1">
			<el-form inline :model="formInlineCheck1" class="demo-form-inline">
				<el-form-item label="账户：">
					<el-autocomplete
						v-model.trim="formInlineCheck1.regionManagementGroupsName"
						:fetch-suggestions="querySearchAsync"
						clearable
						placeholder="请输入内容"
					></el-autocomplete>
				</el-form-item>
				<el-form-item label="省：">
					<el-select v-model="formInlineCheck1.economize" placeholder="请选择省" @change="changeEconomize($event, 1)">
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市：">
					<el-select v-model="formInlineCheck1.market" placeholder="请选择市" @change="changeEconomize($event, 2)">
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select v-model="formInlineCheck1.regionCode" placeholder="请选择区县">
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="等级：">
					<el-select v-model="formInlineCheck1.levelDictionaryItemId" placeholder="请选择">
						<el-option v-for="item in levelDictionaryItemList" :key="item.id" :label="item.title" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-filter" v-else>
			<el-form inline :model="formInlineCheck2" class="demo-form-inline">
				<el-form-item label="账户：">
					<el-autocomplete
						v-model.trim="formInlineCheck2.regionManagementGroupsName"
						:fetch-suggestions="querySearchAsync"
						clearable
						placeholder="请输入内容"
					></el-autocomplete>
				</el-form-item>
				<el-form-item label="省：">
					<el-select v-model="formInlineCheck2.economize" placeholder="请选择省" @change="changeEconomize($event, 1)">
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市：">
					<el-select v-model="formInlineCheck2.market" placeholder="请选择市" @change="changeEconomize($event, 2)">
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select v-model="formInlineCheck2.district" placeholder="请选择区县" @change="changeEconomize($event, 3)">
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="乡镇：">
					<el-select v-model="formInlineCheck2.regionCode" placeholder="请选择">
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="机构类别：">
					<el-select v-model="formInlineCheck2.institutionTypeDictionaryItemId" placeholder="请选择">
						<el-option v-for="item in dictionaryList" :key="item.id" :label="item.title" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="tabClass">
				<span :class="{ activeClass: checkTab === 1 }" @click="changeCheck(1)">县级医疗机构</span>
				<span :class="{ activeClass: checkTab === 2 }" @click="changeCheck(2)">乡镇卫生院</span>
				<span :class="{ activeClass: checkTab === 3 }" @click="changeCheck(3)">村卫生室</span>
			</div>
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="checkTab == 1 ? tableHeadCheck1 : checkTab == 2 ? tableHeadCheck2 : tableHeadCheck3"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:operation-column-width="100"
				:is-show-selection="false"
				:showOperation="false"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #account="{ scope }">
					<el-button @click="accountInfoHand(scope.row)" size="mini" type="text"> 账号信息 </el-button>
				</template>
			</Table>
		</div>
		<AccountInfo ref="accountInfo" :isShowDetail="true"></AccountInfo>
	</div>
</template>

<script>
export default {
	name: 'institutionalStatistics',
	components: {
		Table: () => import('@/components/Table/table'),
		AccountInfo: () => import('@/views/accountManager/countyAccount/newDialog'),
	},
	data() {
		return {
			formInlineCheck1: {
				regionManagementGroupsName: '',
				economize: '',
				market: '',
				regionCode: '',
				levelDictionaryItemId: '',
			},
			formInlineCheck2: {
				regionManagementGroupsName: '',
				economize: '',
				market: '',
				district: '',
				regionCode: '',
				institutionTypeDictionaryItemId: '',
			},
			tableHeadCheck1: [
				{
					label: '所属县域账号',
					prop: 'regionManagementGroup',
					formatter: (row) => {
						return row.regionManagementGroup?.title || '-';
					},
				},
				{
					label: '医疗机构代码',
					prop: 'organizationCode',
					formatter: (row) => {
						return row.organizationCode || '-';
					},
				},
				{
					label: '医疗机构名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '所属地市',
					prop: 'regions',
					formatter: (row) => {
						return row.regions && row.regions.length > 0 && row.regions[1] ? row.regions[1].title : '-';
					},
				},
				{
					label: '所属区县',
					prop: 'regions',
					formatter: (row) => {
						return row.regions && row.regions.length > 0 && row.regions[2] ? row.regions[2].title : '-';
					},
				},
				{
					label: '等级',
					prop: 'levelDictionaryItem',
					formatter: (row) => {
						return row.levelDictionaryItem?.title || '-';
					},
				},
				{
					label: '所有制形式',
					prop: 'ownershipDictionaryItem',
					formatter: (row) => {
						return row.ownershipDictionaryItem?.title || '-';
					},
				},
				{
					label: '性质',
					prop: 'characteristic',
					formatter: (row) => {
						return row.characteristic || '-';
					},
				},
				{
					label: '联系人',
					prop: 'principalName',
					formatter: (row) => {
						return row.principalName || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '所属账号',
					prop: 'account',
				},
			],
			tableHeadCheck2: [
				{
					label: '机构代码',
					prop: 'organizationCode',
					formatter: (row) => {
						return row.organizationCode || '-';
					},
				},
				{
					label: '卫生院名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '所属区县',
					prop: 'regions',
					formatter: (row) => {
						return row.regions && row.regions.length > 0 && row.regions[2] ? row.regions[2].title : '-';
					},
				},
				{
					label: '所属乡镇/街道',
					prop: 'regions',
					formatter: (row) => {
						return row.regions && row.regions.length > 0 && row.regions[3] ? row.regions[3].title : '-';
					},
				},
				{
					label: '机构类别',
					prop: 'institutionTypeDictionaryItem',
					formatter: (row) => {
						return row.institutionTypeDictionaryItem?.title || '-';
					},
				},
				{
					label: '联系人',
					prop: 'principalName',
					formatter: (row) => {
						return row.principalName || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '所属账号',
					prop: 'account',
				},
			],
			tableHeadCheck3: [
				{
					label: '机构代码',
					prop: 'organizationCode',
					formatter: (row) => {
						return row.organizationCode || '-';
					},
				},
				{
					label: '卫生室名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '所属区县',
					prop: 'regions',
					formatter: (row) => {
						return row && row.regions && row.regions.length > 0 && row.regions[2] ? row.regions[2].title : '-';
					},
				},
				{
					label: '所属乡镇/街道',
					prop: 'regions',
					formatter: (row) => {
						return row && row.regions && row.regions.length > 0 && row.regions[3] ? row.regions[3].title : '-';
					},
				},
				{
					label: '所属村',
					prop: 'regions',
					formatter: (row) => {
						return row && row.regions && row.regions.length > 0 && row.regions[4] ? row.regions[4].title : '-';
					},
				},
				{
					label: '机构类别',
					prop: 'institutionTypeDictionaryItem',
					formatter: (row) => {
						return row.institutionTypeDictionaryItem?.title || '-';
					},
				},
				{
					label: '联系人',
					prop: 'principalName',
					formatter: (row) => {
						return row.principalName || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '所属账号',
					prop: 'account',
				},
			],
			checkTab: 1,
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			districtList: [], //区县
			marketList: [], //市
			townList: [],
			economizeList: [], //省
			options: [],
			dictionaryList: [],
			levelDictionaryItemList: [],
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	watch: {
		// checkTab: {
		// 	handler(val) {
		// 		if (val == 1) {
		// 			this.getInstitList();
		// 		} else {
		// 			this.getInstitTypeList();
		// 		}
		// 		this.getList();
		// 	},
		// 	deep: true,
		// },
	},
	mounted() {
		if (this.checkTab == 1) {
			this.getInstitList();
		} else {
			this.getInstitTypeList();
		}
		this.getEconomizeList();
		this.getList();
	},
	methods: {
		// 获取机构等级list
		getInstitList() {
			this.$http
				.get(this.api.getDictionaryItems + '.json', { params: { dictionaryCode: 'INSTITUTIONAL_LEVEL' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.levelDictionaryItemList = res.data.collection || [];
						this.levelDictionaryItemList.unshift({
							id: null,
							title: '全部',
						});
					}
				})
				.catch((e) => {});
		},
		// 获取机构类别list
		getInstitTypeList() {
			this.$http
				.get(this.api.getDictionaryItems + '.json', { params: { dictionaryCode: 'INSTITUTIONAL_TYPE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.dictionaryList = res.data.collection || [];
						this.dictionaryList.unshift({
							id: null,
							title: '全部',
						});
					}
				})
				.catch((e) => {});
		},
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api.getChinaRegions)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			switch (type) {
				case 1:
					if (this.checkTab == 1) {
						this.formInlineCheck1.market = null;
						this.formInlineCheck1.regionCode = null;
					} else {
						this.formInlineCheck2.market = null;
						this.formInlineCheck2.district = null;
						this.formInlineCheck2.regionCode = null;
					}
					this.marketList = [];
					this.districtList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					if (this.checkTab == 1) {
						this.formInlineCheck1.regionCode = null;
					} else {
						this.formInlineCheck2.district = null;
						this.formInlineCheck2.regionCode = null;
					}
					this.districtList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 3:
					if (this.checkTab == 1) return;
					this.formInlineCheck2.regionCode = null;
					this.townList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.townList = res.data.collection || [];
								this.townList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		// 查询条件
		onSearch() {
			this.queryData.current = 1;
			this.getList('');
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? '' : (this.loading = true);
			let data = {};
			if (this.checkTab == 1) {
				const { regionManagementGroupsName, economize, market, regionCode, levelDictionaryItemId } = this.formInlineCheck1;
				let code =
					economize && !market && !regionCode
						? economize
						: economize && market && !regionCode
						? market
						: economize && market && regionCode
						? regionCode
						: null;
				data = {
					regionManagementGroupsName,
					regionCode: code,
					levelDictionaryItemId: levelDictionaryItemId,
					...this.queryData,
					discriminationCode: 'H',
				};
			} else {
				const { regionManagementGroupsName, economize, market, district, regionCode, institutionTypeDictionaryItemId } = this.formInlineCheck2;
				let code =
					economize && !market && !district && !regionCode
						? economize
						: economize && market && !district && !regionCode
						? market
						: economize && market && district && !regionCode
						? district
						: economize && market && district && regionCode
						? regionCode
						: null;
				data = {
					regionManagementGroupsName,
					regionCode: code,
					institutionTypeDictionaryItemId: institutionTypeDictionaryItemId,
					...this.queryData,
					discriminationCode: this.checkTab == 2 ? 'C' : 'R',
				};
			}
			this.$http
				.get(this.api.getMedicalOrganizations + '.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.regionManagementGroup.title,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.$forceUpdate();
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
							console.log(this.tableData, 'tableData');
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		accountInfoHand(row) {
			if (row.regionAccount?.id) {
				let data = {
					...row.regionAccount,
					chinaRegionDtoList: row.regions,
				};
				this.$refs.accountInfo.init(data);
			}
		},
		changeCheck(val) {
			this.checkTab = val;
			if (val == 1) {
				this.getInstitList();
			} else {
				this.getInstitTypeList();
			}
			this.getList();
		},
	},
};
</script>

<style lang="scss" scoped>
.demo-form-inline {
	/deep/ .el-select {
		width: 120px !important;
		.el-input {
			width: 120px !important;
			.el-input__inner {
				width: 120px !important;
			}
		}
	}
	/deep/ .el-input {
		width: 200px;
	}
}
.main-content {
	.tabClass {
		width: calc(100% + 32px);
		line-height: 48px;
		border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
		margin-left: -16px;
		padding: 0 16px;
		span {
			display: inline-block;
			line-height: 48px;
			margin-right: 32px;
			font-size: 16px;
			cursor: pointer;
			color: #999999;
			&:hover {
				color: #1db9b1;
				border-bottom: 2px solid #1db9b1;
			}
		}
		.activeClass {
			color: #1db9b1;
			border-bottom: 2px solid #1db9b1;
		}
	}
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>